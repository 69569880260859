@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-6xl uppercase font-bold font-sans pb-9;
    }
    h2 {
        @apply text-5xl font-extrabold uppercase font-sans pb-9;
    }
    h3 {
        @apply text-2xl font-extrabold font-sans pb-3;
    }
    h4 {
        @apply text-xl font-extrabold font-sans pb-6;
    }
    p,
    li {
        font-family: "Raleway", sans-serif;
        font-weight: 400;
        line-height: 25px;
        color: #333;
    }
    li {
        margin-bottom: 14px;
    }
    section {
        margin-left: 0;
        margin-right: 0;
        position: relative;
        @apply md:pb-10 md:pt-12 py-8;
    }
    .inner {
        max-width: 94%;
        margin-left: auto;
        margin-right: auto;
    }
    .inner-lg {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .card {
        box-shadow: 2px 2px 13px -2px #616161;
        border-top: 10px solid #5ca4a9;
        border-radius: 8px 8px 0 0;
        @apply flex-1 px-9 py-9 bg-white;
    }
    .accent-line {
        position: relative;
        z-index: 2;
    }
    .accent-line::after {
        background: #ed6a5a85;
        content: " ";
        position: absolute;
        z-index: -1;
        left: -3px;
        width: 104%;
        height: 100%;
        top: 1px;
    }
    @media (min-width: 768px) {
        .inner-lg {
            width: auto;
            max-width: 1024px;
        }
        .inner {
            max-width: 650px;
        }
    }
}

/* CUSTOM */
html {
    scroll-behavior: smooth;
}
